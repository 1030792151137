<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách voucher'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <router-link
              to="/voucher/add-voucher"
              v-if="checkPermission('COUPON_INSERT')"
            >
              <b-button v-bind:style="btnCreate" size="sm" variant="primary">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col class="pl-0" cols="3">
                <b-input
                  placeholder="Tên chương trình"
                  v-model="apiFilterParam.searchName"
                  class="btn-sm"
                  v-on:keyup.enter="onFilter()"
                ></b-input>
              </b-col>
              <b-col cols="3">
                <b-input
                  placeholder="Tìm theo mã voucher"
                  v-model="apiFilterParam.searchCode"
                  class="btn-sm"
                  v-on:keyup.enter="onFilter()"
                ></b-input>
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <b-input-group>
                    <date-picker
                      placeholder="Từ ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="apiFilterParam.dateFrom"
                    ></date-picker>
                  </b-input-group>
                  <span class="ml-1 mr-1"></span>
                  <b-input-group>
                    <date-picker
                      placeholder="Tới ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="apiFilterParam.dateTo"
                    ></date-picker>
                  </b-input-group>
                </div>
              </b-col>
              <b-col cols="3">
                <b-form-select
                  class="select-style"
                  size="sm"
                  v-model="apiFilterParam.status"
                  :options="listStatus"
                  value-field="id"
                  text-field="name"
                >
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1" style="padding-left: 0">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listData"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(status)="row">
            <span
              v-if="row.item.status"
              v-text="row.item.statusName"
              class="label font-weight-bold label-lg label-light-success label-inline"
              style="width: max-content"
            ></span>
            <span
              v-if="!row.item.status"
              v-text="row.item.statusName"
              class="label font-weight-bold label-lg label-light-danger label-inline"
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(startDate)="row">
            <span>Từ: {{ row.item.startDate }}</span>
            <span style="display: block"> Đến: {{ row.item.endDate }} </span>
          </template>
          <template v-slot:cell(createdByName)="row">
            <p class="text-center">{{ row.item.createdByName }}</p>
            <p class="text-center">{{ row.item.createdAt }}</p>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="copyItem(row.item)"
                  v-if="checkPermission('COUPON_INSERT')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="far fa-copy"></i>
                    &nbsp; Sao chép
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('COUPON_UPDATE')"
                  @click="onChangeEditable(row.item)"
                >
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitch2"
                      v-model="row.item.status"
                    />
                    <label
                      class="custom-control-label text-lable"
                      for="customSwitch2"
                      >Mở/ Khóa</label
                    >
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="
                    showDeleteAlert(
                      'Xóa voucher',
                      'Bạn có chắc muốn xóa voucher này không?',
                      row.item,
                      deleteItem,
                    )
                  "
                  v-if="checkPermission('COUPON_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import timeUtils from '@/utils/date';
import { ACTIVE_STATUS_LIST } from '@/utils/constants';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { formatSpecificDate } from '@/utils/date';
import { showDeleteAlert, showConfirmNotify } from '@/utils/sweet-alert2';

export default {
  data() {
    return {
      listData: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      totalPages: 1,
      totalItems: 0,
      page: 1,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'name',
          label: 'Tên chương trình',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '25%',
          },
        },
        {
          key: 'description',
          label: 'Mô tả',
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
        },
        {
          key: 'startDate',
          label: 'Thời gian',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
      ],
      apiFilterParam: {
        searchName: '',
        searchCode: '',
        status: null,
        dateFrom: '',
        dateTo: '',
      },
      dpConfigs: timeUtils.DP_CONFIG,
      listStatus: ACTIVE_STATUS_LIST,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Voucher', route: 'voucher' },
      { title: 'Danh sách voucher' },
    ]);
  },
  created() {
    this.fetchData();
  },
  watch: {},
  methods: {
    editItem: function (item) {
      this.$router.push({
        name: 'update-voucher',
        query: { id: item.id },
      });
    },
    getParamFilter() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      return {
        page: this.page,
        limit: 10,
        searchName: this.apiFilterParam.searchName.trim(),
        searchCode: this.apiFilterParam.searchCode.trim(),
        isActive: this.apiFilterParam.status,
        fromDate: formatSpecificDate(
          this.apiFilterParam.dateFrom,
          'DD/MM/YYYY',
          'yyyy-MM-DD 00:00:00',
        ),
        toDate: formatSpecificDate(
          this.apiFilterParam.dateTo,
          'DD/MM/YYYY',
          'yyyy-MM-DD 23:59:59',
        ),
      };
    },
    fetchData: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = this.getParamFilter();

      ApiService.query('v2/vouchers', { params }).then((response) => {
        const responseData = response.data.data;
        this.totalPages = responseData.totalPage;
        this.totalItems = responseData.totalRow;
        this.listData = responseData.dataset;
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`v2/vouchers/${item.id}`).then((data) => {
        const { status, message } = data.data;
        if (status === 200) {
          makeToastSuccess(message);
          this.fetchData();
        } else {
          makeToastFaile(message);
        }
      });
    },
    showDeleteAlert,
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('COUPON_VIEW')) {
        count++;
      }
      if (localData.checkPermission('COUPON_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-voucher',
      });
      this.fetchData();
    },
    copyItem: function (item) {
      this.$router.push({
        name: 'add-voucher',
        query: { id: item.id, isCreate: true },
      });
    },
    onChangeEditable: async function (rowItem) {
      const content = {
        title: `Mở/ khóa voucher`,
        text: `Bạn có chắc muốn mở/ khóa voucher này không ?`,
        actionName: 'Xác nhận',
        confirmButtonColor: '#3699FF',
      };
      const result = await showConfirmNotify(content);
      if (!result.value) return;
      this.updateItemStatus(rowItem);
    },
    updateItemStatus: async function (item) {
      const payload = {
        voucherConfigId: item.id,
        isActive: item.status === 1 ? 0 : 1,
      };
      await ApiService.put('v2/vouchers/status', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 200) {
            makeToastSuccess(message);
            this.fetchData();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>